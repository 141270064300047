import Layout from "../components/Layout";

import * as React from "react";

export default function IndexPage() {
    return (
        <Layout title="Adventure Store Help Center">
            <h1>Welcome!</h1>
        </Layout>
    );
}
